import * as React from "react"
import 'react-multi-carousel/lib/styles.css';

import {FooterBoxes} from "../datas/resources/content";

import Layout from "../components/layout";


class GlossaryPage extends React.Component {
    render() {
        return (
            <Layout footerBoxes={FooterBoxes}>
                <div className={'glossary-page'}>
                    <main>
                        <div className={'container'}>
                            <h1 className={'main'}>Услуги</h1>
                            <p class="ptext">Делаем сайты, которые создают сильный образ бренда, доносят преимущества и вызывают желание купить</p>
                            <div class="results" bis_skin_checked="1">

         <div class="row alpha-row" bis_skin_checked="1">
            <div class="col-12" bis_skin_checked="1">
               <div class="row" bis_skin_checked="1">
                  <div class="col-12 col-md-2" bis_skin_checked="1">
                     <div class="alpha" bis_skin_checked="1">Разработка</div>
                  </div>
                  <div class="col-12 col-md-10" bis_skin_checked="1">
                     <ul class="result-list">
                        <li class="row result">
                           <a href="/glossary/throughput" bis_skin_checked="1">
                              <div class="col-12" bis_skin_checked="1">
                                 <div class="title" bis_skin_checked="1">Разработка сайтов под ключ</div>
                                 Делаем веб-проекты для организации продаж и обслуживания клиентов: корпоративные сайты                              </div>
                           </a>
                        </li>
                        <li class="row result">
                           <a href="/glossary/the-data-availability-problem" bis_skin_checked="1">
                              <div class="col-12" bis_skin_checked="1">
                                 <div class="title" bis_skin_checked="1">Разрабока чат-бота</div>
                                 Создаем чат-боты, которые упрощают и ускоряют взаимодействие с клиентами и работу внутри компании. Помогаем не только с технической реализацией, продумываем точки контакта и проектируем сценарии работы. Интегрируемся в Telegram, Slack, Whatsapp, FB Messenger и другие популярные мессенджеры.                              </div>
                           </a>
                        </li>
                        <li class="row result">
                           <a href="/glossary/trusted-bridge" bis_skin_checked="1">
                              <div class="col-12" bis_skin_checked="1">
                                 <div class="title" bis_skin_checked="1">Разработка мобильных приложений</div>
                                 С каждым годом растет популярность мобильных приложений, бизнес-идеи уже не заканчиваются на создании только веб-сайта. Плюсами мобильных приложений являются не только их широкие возможности и комфортный интерфейс, но и возможность работы без интернета на разных типах устройств.
                              </div>
                           </a>
                        </li>
                        <li class="row result">
                           <a href="/glossary/trusted-bridge" bis_skin_checked="1">
                              <div class="col-12" bis_skin_checked="1">
                                 <div class="title" bis_skin_checked="1">Разработка интернет-магазинов</div>
                                 Интернет-магазины, которые автоматизируют ваши продажи и увеличат оборот за счет онлайн-клиентов.                              </div>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         <div class="row alpha-row" bis_skin_checked="1">
            <div class="col-12" bis_skin_checked="1">
               <div class="row" bis_skin_checked="1">
                  <div class="col-12 col-md-2" bis_skin_checked="1">
                     <div class="alpha" bis_skin_checked="1">Дизайн</div>
                  </div>
                  <div class="col-12 col-md-10" bis_skin_checked="1">
                     <ul class="result-list">
                        <li class="row result">
                           <a href="/glossary/validator" bis_skin_checked="1">
                              <div class="col-12" bis_skin_checked="1">
                                 <div class="title" bis_skin_checked="1">UI/UX design</div>
                                 Создаём осмысленный и эстетичный дизайн интерфейсов, который помогает компаниям решать бизнес-задачи
                              </div>
                           </a>
                        </li>
                        <li class="row result">
                           <a href="/glossary/validator-set" bis_skin_checked="1">
                              <div class="col-12" bis_skin_checked="1">
                                 <div class="title" bis_skin_checked="1">Исследования и проектирование интерфейсов</div>
                                 Исследуем и моделируем пользовательский опыт. Продумываем взаимодействие человека и интерфейса. Помогаем бизнесу проверять гипотезы, генерировать идеи, принимать решения, создавать и развивать работающие сайты и сервисы.                              </div>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         <div class="row alpha-row" bis_skin_checked="1">
            <div class="col-12" bis_skin_checked="1">
               <div class="row" bis_skin_checked="1">
                  <div class="col-12 col-md-2" bis_skin_checked="1">
                     <div class="alpha" bis_skin_checked="1">Брендинг</div>
                  </div>
                  <div class="col-12 col-md-10" bis_skin_checked="1">
                     <ul class="result-list">
                        <li class="row result">
                              <div class="col-12" bis_skin_checked="1">
                                 <div class="title" bis_skin_checked="1">Фирменный стиль</div>
                                 Разрабатываем систему визуальной идентификации, которая будет долго работать и успешно решать новые дизайн-задачи. Внедряем и фиксируем систему в брендбуке и шаблонах</div>
                        </li>
                        <li class="row result">
                           <a href="/glossary/validator-set" bis_skin_checked="1">
                              <div class="col-12" bis_skin_checked="1">
                                 <div class="title" bis_skin_checked="1">Брендбук</div>
                                 Брендбук для торговой марки или вашей компании – эффективный инструмент, который постоянно работает на положительный имидж. Для каждой уважающей себя компании – это ещё и важный внутренний документ. Он дает возможность понять, чем живет бренд не только персоналу, но и партнерам.                              </div>
                           </a>
                        </li>
                        <li class="row result">
                           <a href="/glossary/validator-set" bis_skin_checked="1">
                              <div class="col-12" bis_skin_checked="1">
                                 <div class="title" bis_skin_checked="1">Брендинг для IT</div>
                                 Бренд-команда из аналитиков, стратегов, дизайнеров, креаторов, продюсеров и рекламных специалистов со специализацией в IT и опытом более десяти лет. Создадим логотип IT-компании или разработаем целостный фирменный стиль и брендбук. Продумаем продуктовую стратегию и внедрим дизайн во все виды коммуникаций, включая HR. Мы развиваем собственное направление разработки и UX/UI-команду, поэтому понимаем специфику отрасли и выстраиваем все процессы под неё.                              </div>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         <div class="row alpha-row" bis_skin_checked="1">
            <div class="col-12" bis_skin_checked="1">
               <div class="row" bis_skin_checked="1">
                  <div class="col-12 col-md-2" bis_skin_checked="1">
                     <div class="alpha" bis_skin_checked="1">Реклама, SEO</div>
                  </div>
                  <div class="col-12 col-md-10" bis_skin_checked="1">
                     <ul class="result-list">
                        <li class="row result">
                              <div class="col-12" bis_skin_checked="1">
                                 <div class="title" bis_skin_checked="1">Контекстная реклама</div>
                                 Управляем контекстной рекламой в Яндекс.Директе, Google Ads, на прайс-площадках.</div>
                        </li>
                        <li class="row result">
                           <a href="/glossary/validator-set" bis_skin_checked="1">
                              <div class="col-12" bis_skin_checked="1">
                                 <div class="title" bis_skin_checked="1">Таргетированная реклама</div>
                                 Делаем соцсети полноценным инструментом продаж.                              </div>
                           </a>
                        </li>
                        <li class="row result">
                           <a href="/glossary/validator-set" bis_skin_checked="1">
                              <div class="col-12" bis_skin_checked="1">
                                 <div class="title" bis_skin_checked="1">SEO: оптимизация</div>
                                 Адаптируем сайты и контент под требования поисковых систем, чтобы получать больше органического трафика.                              </div>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
      
                        </div>
                    </main>
                </div>
            </Layout>
        )
    }
}

export default GlossaryPage
